<template>
  <PageHeader image="header-8" withMobile :title="$lang('title')" :breadcrumbs="$lang('breadcr')" />

  <section class="pt-8 pb-9 bg-blue4">
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-7">
          <h2 class="fw-semibold text-blue2 mb-6">
            {{ $lang("title2") }}
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <CardServices
            v-for="(i, ix) of $lang('items')"
            :key="ix"
            class="mb-6"
            :image="i.image"
            :title="i.title"
            :text="i.body"
          />
        </div>
      </div>

      <div class="text-center">
        <button class="btn btn-blue-grad-1 px-5 mx-auto" @click="goTo('contact_us')">
          {{ $lang("button") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import CardServices from "@/components/CardServices.vue";

export default {
  components: {
    PageHeader,
    CardServices,
  },
};
</script>

<style></style>
