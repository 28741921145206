<template>
  <div class="card card-services">
    <div class="card-header">
      <ImageContainer :fileName="image" background />
    </div>

    <div class="card-body pt-5 pb-5 pr-5">
      <h3 class="fw-bold text-blue2 mb-4">{{ title }}</h3>

      <div v-for="(item, i) of text" :key="i">
        <p v-if="item.type === `p`" :class="item.class"> {{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: "/imgs/services-01.png",
    },
    title: {
      type: String,
      default: "Accomodation in Ireland",
    },
    text: {
      type: String,
      default:
        "Our aim is to make you feel at home in Ireland. Your accommodation is an extremely important part of your whole experience. The different locations where we accommodate students are all close to public transport, shopping centres, supermarkets and banks. With NED College you can rest assured that we will provide you with quality accommodation that best suits your needs. We work with Host Families, Hostels and Student Accommodation. Talk to our team to see which one best fits your needs and budget.",
    },
  },
};
</script>

<style></style>
